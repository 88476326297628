<template>
  <div class="search_wrapper">
    <div class="header">
      高级检索字段说明
    </div>
    <!-- <p class="tip">高级检索字段含义和对应示例值，需要按照搜索字段进行搜索，搜索字段区分大小写，即若想搜索哈希值，需要用"md5"而非"MD5"</p> -->
    <div class="field_content" v-for="(item, index) in tableData" :key="index">
      <p class="title">{{`${index+1}.${item.label}`}}</p>
      <span class="tip">{{item.tip}}</span>
      <div v-for="(titem, tid) in item.data" :key="tid" style="width: 100%">
        <el-table :header-row-style="headerRowStyle" v-if="Array.isArray(titem)" :data="titem">
          <el-table-column prop="field" label="搜索字段" min-width="1" />
          <el-table-column prop="explain" label="字段说明" min-width="1" />
          <el-table-column prop="wildcard" label="模糊搜索&通配符" width="160" />
          <el-table-column prop="example" label="搜索示例" min-width="3">
            <template #default="scope">
              <div v-for="(examit, examid) in scope.row.example" :key="examid">
                <span>{{examit}}</span>
                <template v-if="!scope.row?.hideCopy">
                  <copy-icon
                    style="vertical-align: middle;width:20px;color:var(--color-text-3);font-size:16px;"
                    :right="8"
                    :copyText="examit"
                  />
                  <el-tooltip :effect="$isDark() ? 'dark' : 'light'" content="检索" placement="top">
                    <svg-icon name="sousuo" style="color:#00ab7a;cursor:pointer;" @click="search(examit, scope.row.field)"></svg-icon>
                  </el-tooltip>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="module" label="报告位置" min-width="1">
          </el-table-column>
        </el-table>
        <span v-else @click="xxx" class="explain">{{titem.explain}}</span>
      </div>
      <span class="explain">{{item.explain}}</span>
    </div>
  </div>
</template>

<script setup>
import emitter from '@/app/emitter.js'
import { useGetters } from '@/hooks/useVuex'
import { useRouter } from 'vue-router'
const { gid } = useGetters('user', ['gid'])
const router = useRouter()
const search = (keyword, field) => {
  if (!gid.value) {
    emitter.emit('showLogin')
    return
  }
  router.push({ name: 'advanced-search', query: { keyword: `${field}:"${keyword}"` } })
}

const headerRowStyle = {
  backgroundColor: 'var(--color-bg-4)',
  height: '48px',
  lineHeight: '48px'
}

const tableData = [
  {
    label: '文件',
    data: [
      [
        {
          field: 'md5',
          explain: ['上传文件的md5值'],
          wildcard: '-',
          example: ['ab08101240a28450679f4838c759d0e0'],
          module: '静态分析'
        },
        {
          field: 'sha1',
          explain: ['上传文件的sha1值'],
          wildcard: '-',
          example: ['b9d5d41e2a5b95f6ff6dfc8c728be05d54a21b19'],
          module: '静态分析'
        },
        {
          field: 'sha256',
          explain: ['上传文件的sha256值'],
          wildcard: '-',
          example: ['360ecc637fca8fcda2cda4d0770fc1c818fc199b9da660ed3dab1f402'],
          module: '静态分析'
        },
        {
          field: 'ssdeep',
          explain: ['上传文件的ssdeep值'],
          wildcard: '-',
          example: ['3072:dwHq6qUdDshEHYIaRemgfTSD6rmI1ijYx+:mK6q/2KsbMcmwiw+'],
          module: '静态分析'
        },
        {
          field: 'name',
          explain: ['上传文件的文件名称'],
          wildcard: '支持',
          example: ['J859.exe'],
          module: '静态分析'
        },
        {
          field: 'qex_type',
          explain: ['上传文件的文件类型'],
          wildcard: '-',
          example: ['dll32'],
          module: '静态分析'
        },
        {
          field: 'size',
          explain: ['上传文件的文件大小'],
          wildcard: '-',
          example: ['123392'],
          module: '静态分析'
        }
      ]
    ]
  },
  {
    label: '网络连接',
    data: [
      [
        {
          field: 'domain',
          explain: ['网络连接的域名'],
          wildcard: '支持',
          example: ['thycpyhz.info'],
          module: '网络分析'
        },
        {
          field: 'ip',
          explain: ['网络连接的ip'],
          wildcard: '支持',
          example: ['104.19.223.79'],
          module: '网络分析'
        },
        {
          field: 'country',
          explain: ['网络连接的国家'],
          wildcard: '-',
          example: ['美国'],
          module: '网络分析'
        },
        {
          field: 'uri',
          explain: ['网络连接的uri'],
          wildcard: '支持',
          example: ['http://whatismyipaddress.com/'],
          module: '网络分析'
        },
        {
          field: 'user_agent',
          explain: ['网络连接的http请求代理'],
          wildcard: '支持',
          example: ['Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36 Edg/127.0.0.0'],
          module: '网络分析'
        }
      ]
    ]
  },
  {
    label: '任务信息',
    data: [
      [
        {
          field: 'score_combined',
          explain: ['报告分值'],
          wildcard: '-',
          example: ['报告的总分值，可输入1-10分，0.0-4.9分表示未发现威胁，5.0-7.4分表示可疑，7.5-10.0分表示恶意'],
          module: '报告概览',
          hideCopy: true
        },
        {
          field: 'score',
          explain: ['报告的动态分值'],
          wildcard: '-',
          example: ['报告的动态分值，可输入1-10分，0.0-4.9分表示未发现威胁，5.0-7.4分表示可疑，7.5-10.0分表示恶意'],
          module: '报告概览',
          hideCopy: true
        },
        {
          field: 'threat_level',
          explain: ['报告的静态分值'],
          wildcard: '-',
          example: ['报告的静态分值，可输入“10”代表无风险，“40”代表未检出，“50”代表可疑，“70”代表恶意'],
          module: '报告概览',
          hideCopy: true
        },
        {
          field: 'vname',
          explain: ['报告的静态引擎命中的静态病毒名'],
          wildcard: '支持',
          example: ['Win32/Virus.Neshta.HwUBkWQA'],
          module: '报告概览'
        },
        {
          field: 'image',
          explain: ['报告的运行环境'],
          wildcard: '-',
          example: ['可选项：windows7_32、windows7_64、windows8_32、windows8_64、windows10_32、windows10_64、ubuntu18_64、android11_64'],
          module: '报告概览',
          hideCopy: true
        }
      ]
    ]
  },
  {
    label: '行为信息',
    data: [
      [
        {
          field: 'tagzhcn',
          explain: ['行为标签中文'],
          wildcard: '支持',
          example: ['勒索'],
          module: '报告概览'
        },
        {
          field: 'attackid',
          explain: ['ATT&CK ID'],
          wildcard: '-',
          example: ['T1057'],
          module: '分析概览'
        },
        {
          field: 'sig_class',
          explain: ['行为规则分类'],
          wildcard: '-',
          example: ['可输入内容：anti、apt、bitcoin、bootkit、browser、crypto、exploit、execution、evasion、discovery、install、inject、malware、network、microsoft、persistence、ransomware、security、system、static、other', '规则分类对应中文名称：anti-检测逃避、apt-威胁组织、bitcoin-数字货币、bootkit-引导启动、browser-浏览器、crypto-加密解密、exploit-漏洞利用、execution-命令执行、evasion-防御逃避、discovery-信息发现、install-安装程序、inject-进程注入、malware-威胁家族、network-网络连接、microsoft-应用程序、persistence-持久化、ransomware-勒索病毒、security-系统安全、system-系统活动、static-静态指标、other-其他事件'],
          module: '分析概览',
          hideCopy: true
        },
        {
          field: 'descripzhcn',
          explain: ['行为规则描述'],
          wildcard: '支持',
          example: ['查询所有运行中的进程的列表'],
          module: '分析概览'
        },
        {
          field: 'process_path',
          explain: ['进程的文件路径'],
          wildcard: '-',
          example: ['C:\\Windows\\system32\\cmd.exe'],
          module: '行为分析'
        },
        {
          field: 'parent_path',
          explain: ['父进程路径'],
          wildcard: '-',
          example: ['C:\\Windows\\system32\\cmd.exe'],
          module: '行为分析'
        },
        {
          field: 'parent_name',
          explain: ['父进程名'],
          wildcard: '-',
          example: ['8frufytb_84e0d1f1a.exe'],
          module: '行为分析'
        },
        {
          field: 'command_line',
          explain: [' 进程的命令行'],
          wildcard: '支持',
          example: ['C:\\Users\\admin\\AppData\\Local\\Temp\\FGwANMKs\\8frufytb_84e0d1f1a.exe'],
          module: '行为分析'
        },
        {
          field: 'process_name',
          explain: ['进程名'],
          wildcard: '-',
          example: ['netsh.exe'],
          module: '行为分析'
        },
        {
          field: 'parent_cmdline',
          explain: ['父进程命令行'],
          wildcard: '支持',
          example: ['C:\\Windows\\system32\\cmd.exe'],
          module: '行为分析'
        },
        {
          field: 'user_sid',
          explain: ['用户SID'],
          wildcard: '-',
          example: ['S-1-5-21-1716682544-2954110871-1581947668-1001'],
          module: '行为分析'
        },
        {
          field: 'integrity_sid',
          explain: ['完整性等级'],
          wildcard: '-',
          example: ['S-1-5-21-1716682544-2954110871-1581947668-1001'],
          module: '行为分析'
        },
        {
          field: 'mutex',
          explain: ['互斥量'],
          wildcard: '-',
          example: ['Local\\MSCTF.Asm.MutexDefault1S-1-5-21-1716682544-2954110871-1581947668-1001'],
          module: '行为分析'
        }
      ]
    ]
  },
  {
    label: '释放文件与内存',
    data: [
      [
        {
          field: 'dropped_name',
          explain: ['释放文件名称'],
          wildcard: '-',
          example: ['AdobeUpdate.exe'],
          module: '释放文件'
        },
        {
          field: 'dropped_filepath',
          explain: ['释放文件路径'],
          wildcard: '支持',
          example: ['C:\\USERS\\ADMIN\\APPDATA\\LOCAL\\TEMP\\3582-490\\8FRUFYTB_84E0D1F1A.EXE'],
          module: '释放文件'
        },
        {
          field: 'dropped_md5',
          explain: ['释放文件MD5'],
          wildcard: '-',
          example: ['9d04fdb8cea3a9ebcd3b67532598ab72'],
          module: '释放文件'
        },
        {
          field: 'dropped_sha1',
          explain: ['释放文件sha1'],
          wildcard: '-',
          example: ['93cbb5d03330433f7fbf5efb2a822ffe2b8cb8ee'],
          module: '释放文件'
        },
        {
          field: 'dropped_sha256',
          explain: ['释放文件sha256'],
          wildcard: '-',
          example: ['17aaa05f1d8ccebb22bb9b273b0c57a9cc9d2dabc0e2733ab3c751f02ba882cf'],
          module: '释放文件'
        },
        {
          field: 'dropped_ssdeep',
          explain: ['释放文件SSdeep'],
          wildcard: '-',
          example: ['3072:KAf6NnUGIg0UPv5qBAn74Ky3/ehZvmW1vuZI4rGNtYw:JfanzIGhqJvehlmWMILNaw'],
          module: '释放文件'
        },
        {
          field: 'dropped_threat_level',
          explain: ['释放文件静态分值'],
          wildcard: '-',
          example: ['释放文件的静态分值，可输入“10”代表无风险，“40”代表未检出，“50”代表可疑，“70”代表恶意'],
          module: '释放文件',
          hideCopy: true
        },
        {
          field: 'dropped_vname',
          explain: ['释放文件病毒名'],
          wildcard: '支持',
          example: ['QVM05.1.487B.Malware.Gen'],
          module: '释放文件'
        },
        {
          field: 'memdump_md5',
          explain: ['释放内存MD5'],
          wildcard: '-',
          example: ['ce3597014d88117cd523e6d32cf07fbd'],
          module: '释放内存'
        },

        {
          field: 'memdump_sha1',
          explain: ['释放内存sha1'],
          wildcard: '-',
          example: ['7a260882c8f78aa178637110c8d970e63a480633'],
          module: '释放内存'
        },
        {
          field: 'memdump_sha256',
          explain: ['释放内存sha256'],
          wildcard: '-',
          example: ['79a1f50cf2d93d3f40bddd1ecbd08683f36daa455e3f0f1a88b4325fb10a9d74'],
          module: '释放内存'
        },
        {
          field: 'memdump_ssdeep',
          explain: ['释放内存ssdeep'],
          wildcard: '-',
          example: ['12288:tim9422iZcXEwB4M9j1uAWU0/oN7NNw'],
          module: '释放内存'
        },
        {
          field: 'memdump_threat_level',
          explain: ['释放内存静态分值'],
          wildcard: '-',
          example: ['释放内存的静态分值，可输入“10”代表无风险，“40”代表未检出，“50”代表可疑，“70”代表恶意'],
          module: '释放内存',
          hideCopy: true
        },
        {
          field: 'memdump_vname',
          explain: ['释放内存病毒名'],
          wildcard: '支持',
          example: ['QVM05.1.487B.Malware.Gen'],
          module: '释放内存'
        }
      ]
    ]
  },
  {
    label: '系统信息',
    data: [
      [
        {
          field: 'registry',
          explain: ['注册表键值'],
          wildcard: '支持',
          example: ['HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\'],
          module: '分析概览'
        }
      ]
    ]
  }
]
</script>
<style lang="scss" scoped>
.field-item {
  width: 100%;
  p {
    color: var(--color-text-1);
    font-weight: 500;
  }
}
.field_content {
  margin-bottom: 24px;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-text-2);
  }
  .tip, .explain {
    font-size: 12px;
    color: var(--color-text-2);
    margin-bottom: 12px;
    display: block;
  }
  .explain {
    margin-top: 12px;
  }
  .tip {
    margin: 12px 0;
  }
}
.search_wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  .header {
    font-weight: 500;
    color: var(--color-text-1);
    border-bottom: 1px solid var(--color-border-2);
    padding: 10px 0 24px 0;
    font-size: 36px;
    margin-bottom: 18px;
  }
  .tip {
    margin-bottom: 24px;
    font-size: 14px;
    color: var(--color-text-2);
  }
  padding: 0px 83px 24px;;
  :deep(table) {
    border: none !important;
    th {
      border: none !important;
      background-color: var(--color-bg-3) !important;
      color: var(--color-text-1) !important;
    }
    td {
      border: none !important;
    }
    td.el-table__cell {
      border-bottom: var(--el-table-border) !important;
    }
  }
}
</style>
